import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import styled from 'styled-components';
import { apiBiEmbed } from '../../api/api'; // Import API service

const GrainReceiptQueueOld = ({ t }: WithTranslation) => {
  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  const [iFrameReport, setIFrameReport] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const response = await apiBiEmbed.get('/BiList/GetBiList');
        const filteredReport = response.data.sistemas
          .filter((sistema: any) => sistema.idSistema === 1)
          .flatMap((sistema: any) => sistema.areas.filter((area: any) => area.idSistemaArea === 2))
          .flatMap((area: any) => area.relatorios.filter((relatorio: any) => relatorio.idSistemaAreaReport === 2))
          .map((relatorio: any) => relatorio.reportUrl)[0];

        if (filteredReport) {
          setIFrameReport(filteredReport);
        }
      } catch (error) {
        console.error('Error fetching report data', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [getUserRegistration]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 5px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.grainReceiptQueue')} />
      </StyledPageTitleContainer>
      <LoadingContainerScreen isLoading={isLoading}>
        <br />
      </LoadingContainerScreen>
      {!isEmpty(iFrameReport) && <StyledIframe src={iFrameReport} onLoad={() => setIsLoading(false)} />}
    </>
  );
};

const StyledIframe = styled.iframe`
  width: 100%;
  height: 75vh;
  margin-top: 30px;
`;

export default withTranslation()(GrainReceiptQueueOld);
