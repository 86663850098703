import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import styled, { keyframes } from 'styled-components';
import { StyledButton } from 'components/styled-button/StyledButton';
import { useHistory } from 'react-router-dom';
import { api, apiCore, apiOracle } from '../../api/api';

interface Filial {
  cdFilial: number;
  nmFilial: string;
  qtTempoMedio: string;
  qtTamanhoFila: number;
}

interface Posicao {
  cdFilial: number;
  nmFilial: string;
  nrFila: number;
  cdPessoa: number | null;
  nrPlacaVeiculo: string | null;
  dtEnt: string;
  dtSai: string | null;
}

interface IJsonData {
  filiais: Filial[];
  posicoes: Posicao[];
}

// Criando o spinner de loading com styled-components
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

// Container centralizado para o loader
const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

const GrainReceiptQueue = ({ t }: WithTranslation) => {
  const history = useHistory();
  // Estado para armazenar os dados da API
  const [jsonData, setJsonData] = useState<IJsonData | null>(null);
  // Estado para controlar a expansão dos cards (cada filial)
  const [expanded, setExpanded] = useState<{ [key: number]: boolean }>({});

  const userRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa ?? 0;
  });

  useEffect(() => {
    const fetchJsonData = async () => {
      try {
        // Chamada à API com o parâmetro cd_pessoa
        const response = await apiCore.get<IJsonData>(`/FilaRecebimento/GetInfosWeb?cd_pessoa=${userRegistration}`);
        setJsonData(response.data);
      } catch (error) {
        console.error('Erro ao buscar dados da API:', error);
      }
    };

    fetchJsonData();
  }, [userRegistration]);

  // Se os dados ainda não foram carregados, exibe o spinner
  if (!jsonData) {
    return (
      <LoadingContainerScreen>
        <LoaderContainer>
          <Loader />
          <p>Carregando...</p>
        </LoaderContainer>
      </LoadingContainerScreen>
    );
  }

  // Função para alternar a expansão do card de cada filial
  const toggleCard = (cdFilial: number) => {
    setExpanded(prev => ({
      ...prev,
      [cdFilial]: !prev[cdFilial],
    }));
  };

  const handleRedirect = () => {
    window.location.href = '/agricola/fila-recebimento-grao-old';
  };

  // Estilo para o ícone customizado
  const iconStyle: React.CSSProperties = {
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    border: '3px solid #fff',
    marginRight: '10px',
    fontSize: '18px',
    fontWeight: 'bold',
    cursor: 'pointer',
  };

  // Estilos para o quadro de legendas
  const legendContainerStyle: React.CSSProperties = {
    border: '2px solid #000',
    padding: '10px',
    borderRadius: '5px',
    width: '100%',
    marginBottom: '20px',
    backgroundColor: '#f0f0f0',
  };

  const legendItemStyle: React.CSSProperties = {
    display: 'flex',
    alignItems: 'center',
    marginBottom: '5px',
  };

  const squareStyle: React.CSSProperties = {
    width: '20px',
    height: '20px',
    marginRight: '10px',
    display: 'inline-block',
    border: '1px solid #ccc',
  };

  return (
    <div style={{ padding: '20px' }}>
      {/* Quadro de Legendas */}
      <div style={legendContainerStyle}>
        <h3>Legendas:</h3>
        <div style={legendItemStyle}>
          <div style={{ ...squareStyle, backgroundColor: 'limegreen' }}></div>
          <span>SEUS VEÍCULOS NA FILA</span>
        </div>
        <div style={legendItemStyle}>
          <div style={{ ...squareStyle, backgroundColor: 'cyan' }}></div>
          <span>SEUS VEÍCULOS QUE SAÍRAM RECENTEMENTE</span>
        </div>
        <div style={legendItemStyle}>
          <div style={{ ...squareStyle, backgroundColor: 'yellow' }}></div>
          <span>VEÍCULOS DE OUTRAS PESSOAS NA FILA</span>
        </div>
        <div style={legendItemStyle}>
          <div style={{ ...squareStyle, backgroundColor: 'white' }}></div>
          <span>VEÍCULOS DE OUTRAS PESSOAS QUE SAÍRAM RECENTEMENTE</span>
        </div>
      </div>

      {jsonData.filiais.map(filial => (
        <div
          key={filial.cdFilial}
          style={{
            border: '1px solid #ccc',
            borderRadius: '5px',
            padding: '10px',
            marginBottom: '10px',
            backgroundColor: 'rgba(42, 82, 153, 1)',
            color: 'white',
          }}
        >
          <div onClick={() => toggleCard(filial.cdFilial)} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
            <div style={iconStyle}>{expanded[filial.cdFilial] ? '-' : '+'}</div>
            <div>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <strong>FILIAL:&nbsp;</strong>
                {filial.nmFilial}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <strong>TEMPO MÉDIO:&nbsp;</strong>
                {filial.qtTempoMedio.replace(/ /g, '')}
              </div>
              <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                <strong>TAMANHO DA FILA:&nbsp;</strong>
                {filial.qtTamanhoFila}
              </div>
            </div>
          </div>

          {expanded[filial.cdFilial] && (
            <div style={{ marginTop: '10px' }}>
              <table style={{ width: '100%', borderCollapse: 'collapse' }}>
                <thead>
                  <tr>
                    <th style={{ border: '1px solid #000', padding: '5px' }}>PLACA</th>
                    <th style={{ border: '1px solid #000', padding: '5px' }}>DATA DE ENTRADA</th>
                    <th style={{ border: '1px solid #000', padding: '5px' }}>DATA DE SAÍDA</th>
                    <th style={{ border: '1px solid #000', padding: '5px' }}>POSIÇÃO NA FILA</th>
                  </tr>
                </thead>
                <tbody style={{ color: '#000' }}>
                  {(() => {
                    // Filtra as posições da filial atual
                    const posicoesFilial = jsonData.posicoes.filter(pos => pos.cdFilial === filial.cdFilial);

                    // Função para converter data "DD/MM/YYYY HH:MI:SS" em objeto Date
                    const parseDate = (dateStr: string): Date => {
                      const [data, hora] = dateStr.split(' ');
                      const [dia, mes, ano] = data.split('/');
                      const [horas, minutos, segundos] = hora.split(':');
                      return new Date(Number(ano), Number(mes) - 1, Number(dia), Number(horas), Number(minutos), Number(segundos));
                    };

                    // Separa os itens com nrFila não nulo e nulo
                    const posComNrFila = posicoesFilial.filter(pos => pos.nrFila !== null);
                    const posSemNrFila = posicoesFilial.filter(pos => pos.nrFila === null);

                    // Ordena os itens com nrFila pela propriedade nrFila (numérica)
                    posComNrFila.sort((a, b) => (a.nrFila as number) - (b.nrFila as number));

                    // Ordena os itens sem nrFila pela data de entrada (dtEnt)
                    posSemNrFila.sort((a, b) => parseDate(a.dtEnt).getTime() - parseDate(b.dtEnt).getTime());

                    // Junta os dois arrays: primeiro os com nrFila e depois os demais
                    const posicoesOrdenadas = [...posComNrFila, ...posSemNrFila];

                    return posicoesOrdenadas.map((pos, idx) => {
                      // Definindo a cor de fundo conforme critérios:
                      // • Se nrPlacaVeiculo não for null e dtSai for null -> limegreen
                      // • Se nrPlacaVeiculo não for null e dtSai não for null -> cyan
                      // • Se nrPlacaVeiculo for null e dtSai for null -> yellow
                      // • Caso contrário -> white
                      let backgroundColor = 'white';
                      if (pos.nrPlacaVeiculo !== null) {
                        backgroundColor = pos.dtSai === null ? 'limegreen' : 'cyan';
                      } else if (pos.nrPlacaVeiculo === null && pos.dtSai === null) {
                        backgroundColor = 'yellow';
                      }

                      return (
                        <tr key={idx} style={{ backgroundColor, border: '1px solid #ccc' }}>
                          <td style={{ border: '1px solid #ccc', padding: '5px' }}>{pos.nrPlacaVeiculo || '-'}</td>
                          <td style={{ border: '1px solid #ccc', padding: '5px' }}>{pos.dtEnt}</td>
                          <td style={{ border: '1px solid #ccc', padding: '5px' }}>{pos.dtSai || '-'}</td>
                          <td style={{ border: '1px solid #ccc', padding: '5px' }}>{pos.nrFila || '-'}</td>
                        </tr>
                      );
                    });
                  })()}
                </tbody>
              </table>
            </div>
          )}
        </div>
      ))}
      <div style={{ marginTop: '30px', textAlign: 'center' }}>
        <StyledButton
          style={{ height: '42px', marginLeft: '12%', width: '200px', maxWidth: '200px' }}
          color="orange"
          type="button"
          onClick={() => history.push('/agricola/fila-recebimento-grao-old')}
        >
          <span>{t('agricultural.viewOldGrain')}</span>
        </StyledButton>
      </div>
    </div>
  );
};

export default withTranslation()(GrainReceiptQueue);
