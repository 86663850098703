import GenericTableBlueHeader from 'components/GenericTableBlueHeader';
import InputDateWithSetting from 'components/InputDateWithSetting';
import LoadingContainerScreen from 'components/loading-container-screen/loading-container-screen';
import MainPageTitle from 'components/MainPageTitle';
import dayjs from 'dayjs';
import { IInvoice } from 'model/financial';
import { useEffect, useState } from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import FinancialService from 'services/financial-service';
import styled from 'styled-components';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import { ReactComponent as PdfIconSvg } from '../../images/frisia/icons/blue-pdf-download.svg';
import { ReactComponent as XmlIconSvg } from '../../images/frisia/icons/blue-xml-download.svg';
import { ReactComponent as ZipIconSvg } from '../../images/frisia/icons/blue-zip.svg';
import { StyledInputText } from './MonthlyTransaction';

const MonthlyTransactionInvoice = ({ t }: WithTranslation) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [selectedStartDateFilter, setSelectedStartDateFilter] = useState<Date | null>(null);
  const [selectedEndDateFilter, setSelectedEndDateFilter] = useState<Date | null>(null);
  const [invoiceList, setInvoiceList] = useState<IInvoice[]>([]);
  const [searchInvoiceList, setSearchInvoiceList] = useState<IInvoice[]>([]);
  const [downloadedZip, setDownloadZip] = useState<boolean>(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const yesterday = dayjs().subtract(1, 'day').toDate();

  const getUserRegistration = useSelector<IRootState, number>(state => {
    return state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa;
  });

  useEffect(() => {
    if (selectedStartDateFilter !== null && selectedEndDateFilter !== null) {
      setIsLoading(true);
      let start = dayjs(selectedStartDateFilter).format('YYYY/MM/DD');
      let end = dayjs(selectedEndDateFilter).format('YYYY/MM/DD');

      FinancialService.getInvoices(start.toString(), end.toString(), getUserRegistration.toString()).then(response => {
        setInvoiceList(response);
        setSearchInvoiceList(response);
        setIsLoading(false);
        setDownloadZip(false);
      });
    }
  }, [selectedStartDateFilter, selectedEndDateFilter]);

  useEffect(() => {
    if (searchTerm != '') {
      const filteredData = invoiceList.filter(item => {
        return `${item.NM_REDUZIDO} ${dayjs(item.DT_EMISSAO).format('DD/MM/YY')} ${item.NR_NF} ${item.DS_OPERACAO} ${item.NR_CHAVE_NF}`
          .toLowerCase()
          .includes(searchTerm.toLowerCase());
      });
      setSearchInvoiceList(filteredData);
    } else {
      setSearchInvoiceList(invoiceList);
    }
  }, [searchTerm]);

  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('financial.invoice')} />
        <MainPageTitle fontSize={15} text={t('financial.invoicesSubTitle')} />
      </StyledPageTitleContainer>
      <FiltersContainer>
        <StyledFiltersDateContainer>
          <InputDateWithSetting
            date={selectedStartDateFilter}
            label={t('livestock.startFilter')}
            setDate={setSelectedStartDateFilter}
            maxDate={yesterday}
          />
          <InputDateWithSetting
            date={selectedEndDateFilter}
            label={t('livestock.endFilter')}
            setDate={setSelectedEndDateFilter}
            maxDate={yesterday}
          />
          {selectedStartDateFilter !== null && selectedEndDateFilter !== null && downloadedZip === false ? (
            <a
              onClick={() => setDownloadZip(true)}
              href={'https://servicos.frisia.coop.br/Core/api/ZipDownload/DownloadZIP?CD_PESSOA=' + getUserRegistration.toString()}
              target="_blank"
            >
              <ZipIconSvg />
            </a>
          ) : (
            ''
          )}
        </StyledFiltersDateContainer>
        {invoiceList.length > 0 && (
          <StyledInputText
            inputProps={{ disableUnderline: true }}
            label={t('global.button.searchFor')}
            onChange={e => setSearchTerm(e.target.value)}
            variant="outlined"
            type={'text'}
          />
        )}
      </FiltersContainer>
      {selectedStartDateFilter !== null && selectedEndDateFilter !== null ? (
        <LoadingContainerScreen isLoading={isLoading}>
          <GenericTableBlueHeader
            headerItems={[
              t('table.branch'),
              t('table.issue'),
              t('agricultural.invoice'),
              t('table.operation'),
              t('table.keyNF'),
              t('table.DANF-e'),
              t('table.XML'),
            ]}
            tableItems={searchInvoiceList.map(item => {
              return {
                branch: item.NM_REDUZIDO,
                issue: dayjs(item.DT_EMISSAO).format('DD/MM/YY'),
                invoice: item.NR_NF,
                operation: item.DS_OPERACAO,
                keyNF: item.NR_CHAVE_NF,
                DANF: (
                  <IconContainer href={item.URL_DANFE_PDF}>
                    <PdfIconSvg />
                  </IconContainer>
                ),
                XML: (
                  <a href={item.URL_XML}>
                    <XmlIconSvg />
                  </a>
                ),
              };
            })}
            dataColor="#787878"
            isInvertLineColor
            isDontHaveMaximumWidth
          />
        </LoadingContainerScreen>
      ) : (
        <WarningContainer>
          <div>!</div>
          {t('financial.filterWarning')}
        </WarningContainer>
      )}
    </>
  );
};

const StyledFiltersDateContainer = styled.div`
  display: flex;
  column-gap: 15px;
  row-gap: 15px;
  flex-wrap: wrap;
  max-width: 830px;
`;

const FiltersContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const IconContainer = styled.a`
  margin: 7px 18px;
  cursor: pointer;
  padding: 0px 11px;
`;

const WarningContainer = styled.div`
  top: 444px;
  left: 602px;
  height: 39px;
  text-align: left;
  font-family: ${({ theme }) => theme.font.montserratRegular};
  font-size: 32px;
  letter-spacing: 0px;
  color: #656565;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 130px;
  z-index: -1;
  div {
    border-radius: 50%;
    top: 427px;
    left: 502px;
    width: 72px;
    height: 72px;
    border: 3px solid #707070;
    font-size: 72px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
  }
`;

export default withTranslation()(MonthlyTransactionInvoice);
