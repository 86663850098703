import LinkedCards from 'components/LinkedCards';
import MainPageTitle from 'components/MainPageTitle';
import CardNames from 'model/enums/card-names';
import { withTranslation, WithTranslation } from 'react-i18next';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';

const listCardNames: CardNames[] = [
  CardNames.STOCK,
  CardNames.LOAD_LOAD,
  CardNames.PRODUCTION_DISCHARGE,
  CardNames.GRAIN_RECEIPT_QUEUE,
  CardNames.PACKAGE,
  CardNames.TRR_REQUESTS_AGRICULTURAL,
];

const inputsListCardNames: CardNames[] = [CardNames.INPUTS, CardNames.PROGRAMMING_BALANCE, CardNames.COSTING_OPTIONS];

const salesListCardNames: CardNames[] = [
  CardNames.SALES_STATEMENT,
  CardNames.SALES_AUTHORIZATION,
  CardNames.CONSULT_SALES_AUTHORIZATION,
  CardNames.PRODUCT_WITHDRAW_AUTHORIZATION,
  CardNames.AGRICULTURAL_QUOTES,
  CardNames.PROFITABILITY,
];

const AgriculturalMenu = ({ t }: WithTranslation) => {
  return (
    <>
      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={30} text={t('agricultural.mainTitle')} />
      </StyledPageTitleContainer>

      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={24} text={t('agricultural.moviment')} />
      </StyledPageTitleContainer>

      <LinkedCards listCardNames={listCardNames} spacingBetweenCards={21.3} />

      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={24} text={t('agricultural.inputs')} />
      </StyledPageTitleContainer>
      <LinkedCards listCardNames={inputsListCardNames} spacingBetweenCards={21.3} />

      <StyledPageTitleContainer margin={'10px 0 25px 0'}>
        <MainPageTitle fontSize={24} text={t('agricultural.sales')} />
      </StyledPageTitleContainer>
      <LinkedCards listCardNames={salesListCardNames} spacingBetweenCards={21.3} />
    </>
  );
};

export default withTranslation()(AgriculturalMenu);
