import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import styled, { keyframes } from 'styled-components';
import { useSelector } from 'react-redux';
import { IRootState } from 'reducer';
import { apiCore } from '../../api/api';
import StyledPageTitleContainer from 'styled-components/StyledPageTitleContainer';
import MainPageTitle from 'components/MainPageTitle';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

/* ========================= Spinner Styles ========================= */
const spin = keyframes`
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
`;

const Loader = styled.div`
  border: 6px solid #f3f3f3;
  border-top: 6px solid #3498db;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: ${spin} 1s linear infinite;
`;

const LoaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
`;

/* ========================= Botão Padronizado ========================= */
const StyledButton = styled.button`
  background-color: #4e6ba7;
  color: #fff;
  border: none;
  padding: 0.5rem 1rem;
  border-radius: 5px;
  cursor: pointer;
  &:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
`;

/* ========================= Outros Styled Components e Interfaces ========================= */
interface IOptionLinha1 {
  lin1DtInsercao: string | null;
  lin1CdTipoOpcaoCusteio: number;
  lin1NmTipoOpcaoCusteio: string;
  lin1VlOpcao: number | null;
}

interface IOptionLinha2 {
  lin2DtInsercao: string | null;
  lin2CdTipoOpcaoCusteio: number;
  lin2NmTipoOpcaoCusteio: string;
  lin2VlLabelExigeReferencia: string | null;
  lin2VlLabelExigeValor: string | null;
  lin2VlLabelExigeData: string | null;
  lin2VlLabelExigeString: string | null;
  lin2VlReferencia: string | null;
  lin2DsReferencia: string | null;
  lin2VlOpcao: number | null;
  lin2DtExigida: string | null;
  lin2VlString: string | null;
}

export interface IDadosCusteio {
  nrMatricula: number;
  cdEmpresa: number;
  cdAgrupador: number;
  cdSafra: number;
  ieTipoSafra: string;
  nmTipoSafra: string;
  cdCultura: number;
  nmCultura: string;
  ieProposito: string;
  nmProposito: string;
  cdTipoAtividade: number;
  nrPropriedade: number;
  nmPropriedade: string;
  qtArea: number;
  vlInsumo: number;
  vlMaoObra: number;
  ieOpcaoRealizada: boolean;
  linhas1: IOptionLinha1[];
  linhas2: IOptionLinha2[];
}

interface ISafra {
  cdSafra: number;
  nmSafra: string;
}

interface IDadosCusteioData {
  sucesso: boolean;
  mensagem: string;
  opcoes: IDadosCusteio[];
}

interface IReferencia {
  cdReferencia: string;
  vlReferencia: string;
}

/* Outros Styled Components */
const Row = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0.5rem 0;
  &:hover {
    background-color: #f7f7f7;
  }
`;

const LabelContainer = styled.div`
  width: 33%;
  padding: 0.5rem;
  ${Row}:hover & {
    background-color: #e1f0ff;
  }
`;

const ValueContainer = styled.div`
  width: 33%;
  padding: 0.5rem;
  ${Row}:hover & {
    background-color: #e6ffe6;
  }
`;

const WarningCard = styled.div`
  min-width: 400px;
  background: #e6eaf2;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  margin: 5px;
  padding: 20px;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .text-blue {
    color: #2a5299;
    font-weight: bold;
  }
`;

const SummaryCard = styled.div`
  min-width: 400px;
  background: #e6eaf2;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 16px;
  margin: 5px;
  padding: 20px;
  text-align: left;
  line-height: normal;
  font-size: 16px;
  font-family: 'Montserrat', sans-serif;
  color: #000000;
  display: flex;
  flex-direction: column;
  justify-content: space-around;

  .text-blue {
    color: #2a5299;
    font-weight: bold;
  }
`;

const ModalOverlay = styled.div`
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 1000;
`;

const ModalContent = styled.div`
  background: white;
  padding: 20px;
  border-radius: 8px;
  max-width: 80%;
  max-height: 80vh;
  overflow-y: auto;
  text-align: center;

  h3 {
    margin-bottom: 20px;
    color: black;
  }
`;

const WarningGrid = styled.div`
  display: flex;
  flex-direction: column;
`;

const WarningRow = styled.div<{ fullWidth?: boolean }>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '30% 70%')};
  column-gap: 1rem;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: #ef9a9a;
  }
`;

const SummaryGrid = styled.div`
  display: flex;
  flex-direction: column;
  background-color: rgb(199 243 203);
  color: rgb(2, 100, 38);
`;

const SummaryRow = styled.div<{ fullWidth?: boolean }>`
  display: grid;
  grid-template-columns: ${({ fullWidth }) => (fullWidth ? '1fr' : '30% 70%')};
  column-gap: 1rem;
  transition: background-color 0.2s ease;
  &:hover {
    background-color: rgb(128, 214, 110);
  }
`;

const tiposAtividade: { [key: number]: string } = {
  1: 'AGRICULTURA',
  2: 'SUINOCULTURA',
  27: 'SILVICULTURA',
  6: 'PECUARIA DE LEITE',
  7: 'PECUARIA DE CORTE',
};

/* ========================= CostOptions Component ========================= */
interface CostOptionsProps extends WithTranslation {
  data: IDadosCusteio;
  reloadData: () => void;
  cgpMapping: { [key: number]: { vlTotal: number; vlDisponivel: number } };
  updateCgpMapping: (cdTipoAtividade: number, data: { vlTotal: number; vlDisponivel: number }) => void;
  updateSingleCard: (cdAgrupador: number) => void;
}

const CostOptions = withTranslation()((props: CostOptionsProps) => {
  const { t, data, reloadData, cgpMapping, updateCgpMapping, updateSingleCard } = props;
  const [expanded, setExpanded] = useState(false);
  const [linhas1, setLinhas1] = useState([...data.linhas1].sort((a, b) => a.lin1CdTipoOpcaoCusteio - b.lin1CdTipoOpcaoCusteio));
  const [linhas2, setLinhas2] = useState([...data.linhas2].sort((a, b) => a.lin2CdTipoOpcaoCusteio - b.lin2CdTipoOpcaoCusteio));
  const [linhas2Referencias, setLinhas2Referencias] = useState<{ [key: number]: IReferencia[] }>({});
  const [showModal, setShowModal] = useState(false);
  const [actionType, setActionType] = useState<'save' | 'delete' | null>(null);
  const [showCalcModal, setShowCalcModal] = useState(false);

  // Controle do modo “atribuir valor”
  const [atributing, setAtributing] = useState<{ [key: number]: boolean }>({});
  const [selectedFonteMap, setSelectedFonteMap] = useState<{ [key: number]: string }>({});
  const [modalRow, setModalRow] = useState<number | null>(null);

  const userRegistration = useSelector<IRootState, number>(
    state => state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa ?? 0
  );

  const formatCurrency = (value: any) => {
    if (value == null) return '';
    return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
  };

  const parseDate = (dateString: string) => {
    const [date, time] = dateString.split(' ');
    const [day, month, year] = date.split('/');
    return `${year}-${month}-${day}`;
  };

  const getOracleDateString = () => {
    const now = new Date();
    const dd = String(now.getDate()).padStart(2, '0');
    const mm = String(now.getMonth() + 1).padStart(2, '0');
    const yyyy = now.getFullYear();
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    return `${dd}/${mm}/${yyyy} ${hours}:${minutes}:${seconds}`;
  };

  const fetchLinhas2Referencias = async (cdTipoOpcaoCusteio: number, index: number) => {
    try {
      const response = await apiCore.get(`/OpcaoCusteio/ReferenciaTipo2?cdTipoOpcaoCusteio2=${cdTipoOpcaoCusteio}`);
      if (response.data.sucesso) {
        setLinhas2Referencias(prev => ({
          ...prev,
          [index]: response.data.referencias,
        }));
      }
    } catch (error) {
      console.error('Erro ao buscar referências para linhas2:', error);
    }
  };

  const handleLinhas1Change = (index: number, field: string, value: any) => {
    const updated = [...linhas1];
    updated[index] = { ...updated[index], [field]: value };
    setLinhas1(updated);
  };

  const handleLinhas2Change = (index: number, field: string, value: any) => {
    const updated = [...linhas2];
    if (field === 'lin2DsReferencia') {
      const selectedReference = linhas2Referencias[index]?.find(ref => ref.cdReferencia === value);
      updated[index] = {
        ...updated[index],
        lin2DsReferencia: selectedReference?.vlReferencia ?? '',
        lin2VlReferencia: value,
      };
    } else {
      updated[index] = { ...updated[index], [field]: value };
    }
    setLinhas2(updated);
  };

  const handleCheckboxLin2 = (index: number, checked: boolean) => {
    const updated = [...linhas2];
    if (checked) {
      updated[index] = {
        ...updated[index],
        lin2DtInsercao: getOracleDateString(),
      };
    } else {
      updated[index] = {
        ...updated[index],
        lin2DtInsercao: null,
        lin2DsReferencia: null,
        lin2VlOpcao: 0, // Zera o valor quando desmarcado
        lin2DtExigida: null,
        lin2VlString: null,
      };
      // Reseta o modo atributing e a fonte selecionada, se existir.
      setAtributing(prev => ({ ...prev, [index]: false }));
      setSelectedFonteMap(prev => {
        const newObj = { ...prev };
        delete newObj[index];
        return newObj;
      });
    }
    setLinhas2(updated);
  };

  const isLinhas2Valid = () => {
    return linhas2.every(item => {
      if (item.lin2DtInsercao === null) return true;
      const isValid =
        (item.lin2VlLabelExigeReferencia ? item.lin2DsReferencia !== null : true) &&
        (item.lin2VlLabelExigeValor ? item.lin2VlOpcao !== null : true) &&
        (item.lin2VlLabelExigeData ? item.lin2DtExigida !== null : true) &&
        (item.lin2VlLabelExigeString ? item.lin2VlString !== null && item.lin2VlString !== '' : true);
      if (!isValid) {
        toast.error(t('Por favor, preencha todos os campos obrigatórios para as opções extras ativas.'));
      }
      return isValid;
    });
  };

  useEffect(() => {
    linhas2.forEach((item, index) => {
      if (item.lin2VlLabelExigeReferencia !== null && !linhas2Referencias[index]) {
        fetchLinhas2Referencias(item.lin2CdTipoOpcaoCusteio, index);
      }
    });
  }, [linhas2]);

  const fetchCgpData = async () => {
    try {
      const response = await apiCore.get(
        `/OpcaoCusteio/CGPDados?nrMatricula=${userRegistration}&nrAtividade=${data.cdTipoAtividade}&cdSafra=${data.cdSafra}`
      );
      updateCgpMapping(data.cdTipoAtividade, {
        vlTotal: response.data.vlTotal,
        vlDisponivel: response.data.vlDisponivel,
      });
    } catch (error) {
      console.error('Erro ao buscar dados CGP:', error);
    }
  };

  useEffect(() => {
    if (expanded) {
      fetchCgpData();
    }
  }, [expanded]);

  const currentCgp = cgpMapping[data.cdTipoAtividade];

  const optouMaoObra = linhas2.some(linha => linha.lin2DtInsercao !== null && linha.lin2CdTipoOpcaoCusteio === 4);
  const totalCusteio = Number((data.vlInsumo + (optouMaoObra ? data.vlMaoObra : 0)).toFixed(2));
  const totalSum = Number(
    (
      linhas1.reduce((acc, cur) => acc + Number(cur.lin1VlOpcao ?? 0), 0) +
      linhas2
        .filter(item => !(item.lin2VlLabelExigeValor && item.lin2VlLabelExigeReferencia === null))
        .reduce((acc, cur) => acc + Number(cur.lin2VlOpcao ?? 0), 0)
    ).toFixed(2)
  );

  const valorInsumos = Number(Math.min(totalSum, data.vlInsumo).toFixed(2));
  const valorMaoDeObra = Number(totalSum > data.vlInsumo ? totalSum - data.vlInsumo : 0).toFixed(2);

  const btnViewColor = data.ieOpcaoRealizada ? 'orange' : 'cyan';
  const btnSaveDeleteColor = data.ieOpcaoRealizada ? 'red' : 'green';
  const btnViewText = expanded
    ? data.ieOpcaoRealizada
      ? t('Minimizar Opção de Custeio Realizada')
      : t('Minimizar Opção de Custeio Disponível')
    : data.ieOpcaoRealizada
    ? t('Visualizar Opção de Custeio Realizada')
    : t('Visualizar Opção de Custeio Disponível');
  const btnSaveDelete = data.ieOpcaoRealizada ? t('Excluir Opção de Custeio') : t('Salvar Opção de Custeio');

  const handleToggle = () => setExpanded(!expanded);

  const isCgpInsufficient =
    !data.ieOpcaoRealizada &&
    currentCgp &&
    linhas1.some(
      item => item.lin1CdTipoOpcaoCusteio === 6 && item.lin1VlOpcao !== null && Number(item.lin1VlOpcao) > currentCgp.vlDisponivel
    );

  const handleSave = async () => {
    if (!isLinhas2Valid()) return;
    if (isCgpInsufficient) {
      toast.error(t('Saldo de Capital de Giro Próprio insuficiente para a atividade'));
      return;
    }
    try {
      // Filtra apenas as linhas1 cujo valor seja maior que zero
      const linhas1Validas = linhas1.filter(item => Number(item.lin1VlOpcao) > 0);

      // Filtra apenas as linhas2 que estão ativas (flagadas) e cujo valor seja maior que zero
      const linhas2Validas = linhas2.filter(item => item.lin2DtInsercao === null || Number(item.lin2VlOpcao) >= 0);
      const response = await apiCore.post('/OpcaoCusteio/SalvarOpcao', {
        cdAgrupador: data.cdAgrupador,
        ieProposito: data.ieProposito,
        linhas1: linhas1Validas.filter(item => item.lin1VlOpcao !== null),
        linhas2: linhas2Validas.filter(item => item.lin2DtInsercao !== null),
      });
      if (response.data.sucesso) {
        toast.success(response.data.mensagem);
        updateSingleCard(data.cdAgrupador);
        fetchCgpData();
      } else {
        toast.error(response.data.mensagem);
      }
    } catch (error) {
      console.error('Erro ao salvar opções de custeio:', error);
      toast.error(t('Erro ao salvar opções de custeio.'));
    }
  };

  const handleDelete = async () => {
    try {
      const response = await apiCore.delete('/OpcaoCusteio/ExcluirOpcao', {
        data: {
          cdAgrupador: data.cdAgrupador,
          ieProposito: data.ieProposito,
        },
      });
      if (response.data.sucesso) {
        toast.success(response.data.mensagem);
        updateSingleCard(data.cdAgrupador);
        fetchCgpData();
      } else {
        toast.error(response.data.mensagem);
      }
    } catch (error) {
      console.error('Erro ao excluir opções de custeio:', error);
      toast.error(t('Erro ao excluir opções de custeio.'));
    }
  };

  const confirmAction = () => {
    setShowModal(false);
    if (actionType === 'save') {
      handleSave();
    } else if (actionType === 'delete') {
      handleDelete();
    }
  };

  // Gerando a lista de opções para o combobox (fontes de recurso)
  const fonteOptions = [
    ...linhas1.map((item, i) => ({
      value: `lin1-${i}`,
      label: item.lin1NmTipoOpcaoCusteio,
      current: Number(item.lin1VlOpcao) || 0,
      tipo: 'lin1',
    })),
    ...linhas2
      .filter(item => item.lin2VlLabelExigeValor !== null && item.lin2VlLabelExigeReferencia !== null)
      .map((item, i) => ({
        value: `lin2-${i}`,
        label: item.lin2NmTipoOpcaoCusteio,
        current: Number(item.lin2VlOpcao) || 0,
        tipo: 'lin2',
      })),
  ];

  const shouldShowWarning = !data.ieOpcaoRealizada && (totalSum < data.vlInsumo || totalSum > totalCusteio || isCgpInsufficient);

  return (
    <div
      style={{
        border: '1px solid #ccc',
        margin: '1rem',
        padding: '1rem',
        borderRadius: '5px',
        marginBottom: '10px',
        backgroundColor: 'rgba(42, 82, 153, 1)',
        color: 'white',
      }}
    >
      {/* Cabeçalho do card */}
      <div style={{ marginBottom: '1rem' }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Cultura')}: </span>
            <span>{data.nmCultura}</span>
          </div>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Tipo de Safra')}: </span>
            <span>{data.nmTipoSafra}</span>
          </div>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Propósito')}: </span>
            <span>{data.nmProposito}</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '0.5rem' }}>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Propriedade')}: </span>
            <span>{data.nmPropriedade}</span>
          </div>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Área')}: </span>
            <span>{data.qtArea}</span>
          </div>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Atividade')}: </span>
            <span>{tiposAtividade[data.cdTipoAtividade]}</span>
          </div>
        </div>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Mão de Obra')}: </span>
            <span>{formatCurrency(data.vlMaoObra)}</span>
          </div>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Insumos')}: </span>
            <span>{formatCurrency(data.vlInsumo)}</span>
          </div>
          <div style={{ flexBasis: '33%', textAlign: 'left' }}>
            <span style={{ fontWeight: 'bold' }}>{t('Total')}: </span>
            <span>{formatCurrency(totalCusteio)}</span>
          </div>
        </div>
      </div>

      {/* Conteúdo expandido */}
      {expanded && (
        <div style={{ marginTop: '1rem', padding: '1rem', background: '#fff', color: 'black' }}>
          <div style={{ marginBottom: '1rem' }}>
            {currentCgp && (
              <div style={{ marginBottom: '1rem' }}>
                <WarningCard>
                  <p className="text-blue">
                    {t(`CAPITAL DE GIRO PRÓPRIO TOTAL (${tiposAtividade[data.cdTipoAtividade]})`)}: {formatCurrency(currentCgp.vlTotal)}
                  </p>
                  <p className="text-blue">
                    {t(`CAPITAL DE GIRO PRÓPRIO DISPONÍVEL (${tiposAtividade[data.cdTipoAtividade]})`)}:{' '}
                    {formatCurrency(currentCgp.vlDisponivel)}
                  </p>
                </WarningCard>
              </div>
            )}
            {shouldShowWarning && totalSum > 0 && (
              <div
                style={{
                  marginBottom: '1rem',
                  padding: '0.5rem',
                  backgroundColor: '#ffcdd2',
                  color: '#b71c1c',
                  borderRadius: '4px',
                  textAlign: 'left',
                }}
              >
                <WarningGrid>
                  <WarningRow>
                    <div style={{ fontWeight: 'bold' }}>Existem erros nas opções realizadas:</div>
                    <div
                      style={{ cursor: 'pointer', textAlign: 'right', textDecoration: 'underline', paddingRight: '20px' }}
                      onClick={() => setShowCalcModal(true)}
                    >
                      Clique aqui para entender melhor
                    </div>
                  </WarningRow>
                  <br />
                  <WarningRow>
                    <div>
                      Valor de Insumos (obrigatório):
                      <span
                        title="É o valor da programação de insumos. Deve ser completamente custeado, o sistema não permite o custeio parcial deste valor."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(183, 28, 28)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div>{formatCurrency(data.vlInsumo)}</div>
                  </WarningRow>
                  <WarningRow>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>
                      Valor de Mão de Obra (opcional):
                      <span
                        title="É habilitado quando a Opção Extra 'FINANCIAR MAO DE OBRA' for selecionada. Este valor não tem obrigatoriedade de ser custeado e pode ser custeado parcialmente."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(183, 28, 28)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>{formatCurrency(data.vlMaoObra)}</div>
                  </WarningRow>
                  <WarningRow>
                    <div>
                      Valor máximo custeável:
                      <span
                        title="É o valor da programação de insumos e, caso a Opção Extra 'FINANCIAR MAO DE OBRA' esteja selecionada, inclui também o valor de Mão de Obra."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(183, 28, 28)',
                        }}
                      >
                        ?
                      </span>{' '}
                    </div>
                    <div>{formatCurrency(totalCusteio)}</div>
                  </WarningRow>
                  <br />
                  <WarningRow>
                    <div>
                      Sua soma de Insumos:
                      <span
                        title="Soma dos valores em R$ informados nos campos digitáveis acima até o limite do valor da programação de insumos."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(183, 28, 28)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div>
                      {formatCurrency(valorInsumos)}
                      {totalSum < data.vlInsumo ? (
                        <span>{` 🡆 Valor de Insumos faltante: ${formatCurrency(data.vlInsumo - totalSum)}.`}</span>
                      ) : null}
                      {totalSum > data.vlInsumo ? (
                        <span>{optouMaoObra ? null : ` 🡆 Valor de Insumos excedente: ${formatCurrency(totalSum - totalCusteio)}.`}</span>
                      ) : null}
                      {isCgpInsufficient ? (
                        <span>{optouMaoObra ? null : ` 🡆 Capital de Giro Próprio insuficiente para a atividade.`}</span>
                      ) : null}
                    </div>
                  </WarningRow>
                  <WarningRow>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>
                      Sua soma de Mão de Obra:
                      <span
                        title="É habilitado quando a Opção Extra 'FINANCIAR MAO DE OBRA' for selecionada. É a parte da soma dos valores em R$ informados nos campos digitáveis que excede o valor da programação de insumos."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(183, 28, 28)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>
                      {optouMaoObra ? formatCurrency(valorMaoDeObra) : formatCurrency(0)}
                      {optouMaoObra && totalSum > totalCusteio && (
                        <span>{` 🡆 Valor de Mão de Obra excedente: ${formatCurrency(totalSum - totalCusteio)}`}</span>
                      )}
                      {optouMaoObra && isCgpInsufficient && <span> 🡆 Capital de Giro Próprio insuficiente para a atividade.</span>}
                    </div>
                  </WarningRow>
                  <WarningRow>
                    <div>
                      Sua soma total:
                      <span
                        title="Somatória total dos valores em R$ informados nos campos digitáveis acima."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(183, 28, 28)',
                        }}
                      >
                        ?
                      </span>{' '}
                    </div>
                    <div>{formatCurrency(totalSum)}</div>
                  </WarningRow>
                </WarningGrid>
              </div>
            )}
            {!shouldShowWarning && (
              <div
                style={{
                  marginBottom: '1rem',
                  padding: '0.5rem',
                  backgroundColor: 'rgb(199 243 203)',
                  color: '#b71c1c',
                  borderRadius: '4px',
                  textAlign: 'left',
                }}
              >
                <SummaryGrid>
                  <SummaryRow>
                    <div style={{ fontWeight: 'bold' }}>Resumo da solicitação:</div>
                    <div
                      style={{
                        cursor: 'pointer',
                        textAlign: 'right',
                        textDecoration: 'underline',
                        paddingRight: '20px',
                      }}
                      onClick={() => setShowCalcModal(true)}
                    >
                      Clique aqui para entender melhor
                    </div>
                  </SummaryRow>
                  <br />
                  <SummaryRow>
                    <div>
                      Valor de Insumos (obrigatório):
                      <span
                        title="É o valor da programação de insumos. Deve ser completamente custeado, o sistema não permite o custeio parcial deste valor."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(2, 100, 38)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div>{formatCurrency(data.vlInsumo)}</div>
                  </SummaryRow>
                  <SummaryRow>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>
                      Valor de Mão de Obra (opcional):
                      <span
                        title="É habilitado quando a Opção Extra 'FINANCIAR MAO DE OBRA' for selecionada. Este valor não tem obrigatoriedade de ser custeado e pode ser custeado parcialmente."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(2, 100, 38)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>{formatCurrency(data.vlMaoObra)}</div>
                  </SummaryRow>
                  <SummaryRow>
                    <div>
                      Valor máximo custeável:
                      <span
                        title="É o valor da programação de insumos e, caso a Opção Extra 'FINANCIAR MAO DE OBRA' esteja selecionada, inclui também o valor de Mão de Obra."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(2, 100, 38)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div>{formatCurrency(totalCusteio)}</div>
                  </SummaryRow>
                  <br />
                  <SummaryRow>
                    <div>
                      Sua soma de Insumos:
                      <span
                        title="Soma dos valores em R$ informados nos campos digitáveis acima até o limite do valor da programação de insumos."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(2, 100, 38)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div>
                      {formatCurrency(valorInsumos)}
                      {totalSum < data.vlInsumo ? (
                        <span>{` 🡆 Valor de Insumos faltante: ${formatCurrency(data.vlInsumo - totalSum)}.`}</span>
                      ) : null}
                      {totalSum > data.vlInsumo ? (
                        <span>{optouMaoObra ? null : ` 🡆 Valor de Insumos excedente: ${formatCurrency(totalSum - totalCusteio)}.`}</span>
                      ) : null}
                      {isCgpInsufficient ? (
                        <span>{optouMaoObra ? null : ` 🡆 Capital de Giro Próprio insuficiente para a atividade.`}</span>
                      ) : null}
                    </div>
                  </SummaryRow>
                  <SummaryRow>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>
                      Sua soma de Mão de Obra:
                      <span
                        title="É habilitado quando a Opção Extra 'FINANCIAR MAO DE OBRA' for selecionada. É a parte da soma dos valores em R$ informados nos campos digitáveis que excede o valor da programação de insumos."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(2, 100, 38)',
                        }}
                      >
                        ?
                      </span>
                    </div>
                    <div style={{ textDecoration: optouMaoObra ? 'none' : 'line-through' }}>
                      {optouMaoObra ? formatCurrency(valorMaoDeObra) : formatCurrency(0)}
                      {optouMaoObra && totalSum > totalCusteio && (
                        <span>{` 🡆 Valor de Mão de Obra excedente: ${formatCurrency(totalSum - totalCusteio)}`}</span>
                      )}
                      {optouMaoObra && isCgpInsufficient && <span> 🡆 Capital de Giro Próprio insuficiente para a atividade.</span>}
                    </div>
                  </SummaryRow>
                  <SummaryRow>
                    <div>
                      Sua soma total:
                      <span
                        title="Somatória total dos valores em R$ informados nos campos digitáveis acima."
                        style={{
                          marginLeft: '0.3rem',
                          cursor: 'pointer',
                          border: '1px solid #ccc',
                          borderRadius: '50%',
                          padding: '0.0rem 0.25rem',
                          fontSize: '0.6rem',
                          fontWeight: 'bold',
                          verticalAlign: 'super',
                          display: 'inline-block',
                          color: '#fff',
                          backgroundColor: 'rgb(2, 100, 38)',
                        }}
                      >
                        ?
                      </span>{' '}
                    </div>
                    <div>{formatCurrency(totalSum)}</div>
                  </SummaryRow>
                </SummaryGrid>
              </div>
            )}

            <div>
              <h4>{t('Opções Padrão')}</h4>
              <div style={{ marginBottom: '1rem', border: '1px solid #ddd', padding: '1rem', textAlign: 'left' }}>
                {linhas1.map((item, index) => (
                  <Row key={`linhas1-${index}`}>
                    <LabelContainer>
                      <label>{item.lin1NmTipoOpcaoCusteio}:</label>
                    </LabelContainer>
                    <ValueContainer>
                      <input
                        type="number"
                        min="0"
                        value={item.lin1VlOpcao ?? ''}
                        disabled={data.ieOpcaoRealizada}
                        onChange={e => handleLinhas1Change(index, 'lin1VlOpcao', e.target.value)}
                      />
                    </ValueContainer>
                    {item.lin1VlOpcao != null && (
                      <div style={{ width: '33%', padding: '0.5rem' }}>
                        <span>({formatCurrency(item.lin1VlOpcao)})</span>
                      </div>
                    )}
                  </Row>
                ))}
              </div>
            </div>
          </div>

          <h4>{t('Opções Extras')}</h4>
          {linhas2.map((item, index) => (
            <div key={`linhas2-${index}`} style={{ border: '1px solid #ddd', padding: '1rem', textAlign: 'left', marginBottom: '1rem' }}>
              <p>
                <strong>{item.lin2NmTipoOpcaoCusteio}</strong>
              </p>
              <div style={{ marginBottom: '1rem' }}>
                <label>
                  <input
                    type="checkbox"
                    checked={item.lin2DtInsercao != null}
                    onChange={e => handleCheckboxLin2(index, e.target.checked)}
                    disabled={data.ieOpcaoRealizada}
                  />{' '}
                  {(item.lin2DtInsercao != null
                    ? t('Você optou por ')
                    : data.ieOpcaoRealizada
                    ? t('Você NÃO optou por ')
                    : t('Optar por ')) + item.lin2NmTipoOpcaoCusteio}
                </label>
              </div>
              {item.lin2VlLabelExigeReferencia && (
                <Row>
                  <LabelContainer>
                    <label>{item.lin2VlLabelExigeReferencia}</label>
                  </LabelContainer>
                  <ValueContainer>
                    <select
                      value={item.lin2VlReferencia ?? ''}
                      disabled={data.ieOpcaoRealizada || !item.lin2DtInsercao}
                      onChange={e => handleLinhas2Change(index, 'lin2DsReferencia', e.target.value)}
                      required={item.lin2DtInsercao !== null}
                    >
                      <option value="">{data.ieOpcaoRealizada ? item.lin2DsReferencia : t('Selecione...')}</option>
                      {(linhas2Referencias[index] || []).map(ref => (
                        <option key={ref.cdReferencia} value={ref.cdReferencia}>
                          {ref.vlReferencia}
                        </option>
                      ))}
                    </select>
                  </ValueContainer>
                </Row>
              )}
              {item.lin2VlLabelExigeValor && item.lin2VlLabelExigeReferencia !== null && (
                <Row>
                  <LabelContainer>
                    <label>{item.lin2VlLabelExigeValor}</label>
                  </LabelContainer>
                  <ValueContainer>
                    <input
                      type="number"
                      min="0"
                      value={item.lin2VlOpcao ?? ''}
                      disabled={data.ieOpcaoRealizada || !item.lin2DtInsercao}
                      onChange={e => handleLinhas2Change(index, 'lin2VlOpcao', e.target.value)}
                      required={item.lin2DtInsercao !== null}
                    />
                  </ValueContainer>
                  {item.lin2VlOpcao != null && (
                    <div style={{ width: '33%', padding: '0.5rem' }}>
                      <span>({formatCurrency(item.lin2VlOpcao)})</span>
                    </div>
                  )}
                </Row>
              )}
              {item.lin2VlLabelExigeValor && item.lin2VlLabelExigeReferencia === null && (
                <>
                  {atributing[index] ? (
                    <>
                      <Row>
                        <LabelContainer>
                          <label>FONTE DE RECURSO:</label>
                        </LabelContainer>
                        <ValueContainer>
                          <select
                            value={selectedFonteMap[index] || ''}
                            onChange={e =>
                              setSelectedFonteMap(prev => ({
                                ...prev,
                                [index]: e.target.value,
                              }))
                            }
                          >
                            <option value="">{t('Selecione uma fonte')}</option>
                            {fonteOptions.map(option => (
                              <option key={option.value} value={option.value}>
                                {option.label} ({formatCurrency(option.current)})
                              </option>
                            ))}
                          </select>
                        </ValueContainer>
                      </Row>
                      <Row>
                        <LabelContainer>
                          <label>{item.lin2VlLabelExigeValor}</label>
                        </LabelContainer>
                        <ValueContainer>
                          <input
                            type="number"
                            min="0"
                            value={item.lin2VlOpcao ?? ''}
                            onChange={e => handleLinhas2Change(index, 'lin2VlOpcao', e.target.value)}
                            disabled={data.ieOpcaoRealizada || !item.lin2DtInsercao}
                          />
                        </ValueContainer>
                        {item.lin2VlOpcao != null && (
                          <div style={{ width: '33%', padding: '0.5rem' }}>
                            <span>({formatCurrency(item.lin2VlOpcao)})</span>
                          </div>
                        )}
                      </Row>
                      <Row>
                        <ValueContainer style={{ display: 'flex', gap: '0.5rem' }}>
                          <StyledButton onClick={() => setModalRow(index)}>Somar à Fonte de Recurso</StyledButton>
                          <StyledButton
                            onClick={() => {
                              // Ao cancelar, zera lin2VlOpcao e desativa o modo atributing
                              handleLinhas2Change(index, 'lin2VlOpcao', 0);
                              setAtributing(prev => ({ ...prev, [index]: false }));
                              setSelectedFonteMap(prev => {
                                const newObj = { ...prev };
                                delete newObj[index];
                                return newObj;
                              });
                            }}
                          >
                            Cancelar
                          </StyledButton>
                        </ValueContainer>
                      </Row>
                    </>
                  ) : (
                    (() => {
                      // Verifica se é uma linha do tipo 4
                      const isTipo4 = item.lin2CdTipoOpcaoCusteio === 4;
                      // Para qualquer linha, se ieOpcaoRealizada for true, o botão nunca fica habilitado.
                      // Para linhas do tipo 4, o botão só fica habilitado se a opção extra estiver flagada (item.lin2DtInsercao)
                      // e se totalSum for maior ou igual a data.vlInsumo.
                      const buttonDisabled =
                        data.ieOpcaoRealizada || (isTipo4 ? !item.lin2DtInsercao || totalSum < data.vlInsumo : !item.lin2DtInsercao);
                      const buttonText =
                        isTipo4 && totalSum < data.vlInsumo
                          ? t('Primeiramente informe como irá custear os Insumos')
                          : t('Indicar Valor e Fonte de Recurso');

                      return (
                        <Row>
                          <LabelContainer>{/* Label vazio quando não estiver em atributing */}</LabelContainer>
                          <ValueContainer>
                            <StyledButton onClick={() => setAtributing(prev => ({ ...prev, [index]: true }))} disabled={buttonDisabled}>
                              {buttonText}
                            </StyledButton>
                          </ValueContainer>
                        </Row>
                      );
                    })()
                  )}
                </>
              )}
              {item.lin2VlLabelExigeData && (
                <Row>
                  <LabelContainer>
                    <label>{item.lin2VlLabelExigeData}</label>
                  </LabelContainer>
                  <ValueContainer>
                    <input
                      type="date"
                      value={item.lin2DtExigida ? parseDate(item.lin2DtExigida) : ''}
                      disabled={data.ieOpcaoRealizada || !item.lin2DtInsercao}
                      onChange={e =>
                        handleLinhas2Change(
                          index,
                          'lin2DtExigida',
                          e.target.value ? new Date(e.target.value).toLocaleDateString('pt-BR') + ' 00:00:00' : null
                        )
                      }
                      required={item.lin2DtInsercao !== null}
                    />
                  </ValueContainer>
                </Row>
              )}
              {item.lin2VlLabelExigeString && (
                <Row>
                  <LabelContainer>
                    <label>{item.lin2VlLabelExigeString}</label>
                  </LabelContainer>
                  <ValueContainer>
                    <input
                      type="text"
                      value={item.lin2VlString ?? ''}
                      disabled={data.ieOpcaoRealizada || !item.lin2DtInsercao}
                      onChange={e => handleLinhas2Change(index, 'lin2VlString', e.target.value)}
                      required={item.lin2DtInsercao !== null}
                    />
                  </ValueContainer>
                </Row>
              )}
            </div>
          ))}

          {/* Modal de confirmação para somar à fonte */}
          {modalRow !== null &&
            (() => {
              const additional = Number(linhas2[modalRow].lin2VlOpcao) || 0;
              const fonteSelected =
                fonteOptions.find(option => option.value === (selectedFonteMap[modalRow] || '')) ||
                ({ label: '', current: 0 } as typeof fonteOptions[0]);
              const novaSoma = fonteSelected.current + additional;
              return (
                <ModalOverlay>
                  <ModalContent>
                    <h3>
                      {`Deseja incluir o valor de ${linhas2[modalRow].lin2NmTipoOpcaoCusteio} à fonte de recurso ${fonteSelected.label}?`}
                    </h3>
                    <div style={{ textAlign: 'left', marginBottom: '1rem' }}>
                      <p>Valor atual: {formatCurrency(fonteSelected.current)}</p>
                      <p>Valor adicional: {formatCurrency(additional)}</p>
                      <p>
                        Novo valor de {fonteSelected.label}: {formatCurrency(novaSoma)}
                      </p>
                    </div>
                    <div style={{ display: 'flex', gap: '1rem', justifyContent: 'center' }}>
                      <StyledButton
                        onClick={() => {
                          const selectedValue = selectedFonteMap[modalRow] || '';
                          if (!selectedValue) {
                            toast.error('Selecione uma fonte de recurso');
                            return;
                          }
                          const [resourceType, indexStr] = selectedValue.split('-');
                          const resourceIndex = parseInt(indexStr, 10);
                          if (resourceType === 'lin1') {
                            setLinhas1(prev => {
                              const copy = [...prev];
                              copy[resourceIndex].lin1VlOpcao = Number(copy[resourceIndex].lin1VlOpcao || 0) + additional;
                              return copy;
                            });
                          } else if (resourceType === 'lin2') {
                            setLinhas2(prev => {
                              const copy = [...prev];
                              // Se a opção extra ainda não estiver marcada, "flegamos" ela
                              if (!copy[resourceIndex].lin2DtInsercao) {
                                copy[resourceIndex].lin2DtInsercao = getOracleDateString();
                              }
                              copy[resourceIndex].lin2VlOpcao = Number(copy[resourceIndex].lin2VlOpcao || 0) + additional;
                              return copy;
                            });
                          }
                          // Zerando o valor da linha de onde o valor extra estava sendo somado
                          handleLinhas2Change(modalRow, 'lin2VlOpcao', 0);
                          setAtributing(prev => ({ ...prev, [modalRow!]: false }));
                          setSelectedFonteMap(prev => {
                            const newObj = { ...prev };
                            delete newObj[modalRow!];
                            return newObj;
                          });
                          setModalRow(null);
                        }}
                      >
                        Confirmar
                      </StyledButton>
                      <StyledButton
                        onClick={() => {
                          handleLinhas2Change(modalRow, 'lin2VlOpcao', 0);
                          setAtributing(prev => ({ ...prev, [modalRow!]: false }));
                          setSelectedFonteMap(prev => {
                            const newObj = { ...prev };
                            delete newObj[modalRow!];
                            return newObj;
                          });
                          setModalRow(null);
                        }}
                      >
                        Cancelar
                      </StyledButton>
                    </div>
                  </ModalContent>
                </ModalOverlay>
              );
            })()}
          <div style={{ marginBottom: '1rem' }}>
            <WarningCard hidden={!data.ieOpcaoRealizada && shouldShowWarning}>
              <p className="text-blue" style={{ textAlign: 'center' }}>
                Após clicar em "Salvar Opção de Custeio" ou em "Excluir Opção de Custeio" e confirmar, um processamento adicional no sistema
                interno da Frísia ainda será realizado para validar a sua solicitação de custeio, por favor, fique atento à resposta que
                aparecerá no canto superior direito desta página.
              </p>
            </WarningCard>
          </div>
          <StyledButton
            style={{ backgroundColor: btnSaveDeleteColor, marginBottom: '1rem' }}
            hidden={!data.ieOpcaoRealizada && shouldShowWarning}
            onClick={() => {
              setActionType(data.ieOpcaoRealizada ? 'delete' : 'save');
              setShowModal(true);
            }}
          >
            {btnSaveDelete}
          </StyledButton>
        </div>
      )}

      {showModal && (
        <ModalOverlay>
          <ModalContent>
            <h3>{actionType === 'save' ? t('Confirma Salvar as Alterações?') : t('Confirma Excluir esta Opção de Custeio?')}</h3>
            <div style={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
              <StyledButton onClick={confirmAction}>{t('Confirmar')}</StyledButton>
              <StyledButton onClick={() => setShowModal(false)}>{t('Cancelar')}</StyledButton>
            </div>
          </ModalContent>
        </ModalOverlay>
      )}

      {showCalcModal && (
        <ModalOverlay>
          <ModalContent>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● Use as Opções Padrão e Opções Extras para informar como deseja custear o plantio.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>● É possível usar mais de uma fonte de recursos.</p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● O valor de Insumos deve ser custeado completamente, não é possível custear os Insumos parcialmente.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● O valor de Mão de Obra é opcional e, caso disponível, pode ser custeado integralmente, parcialmente ou até mesmo não ser
              custeado.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● Para custear Mão de Obra, a Opção Extra FINANCIAR MAO DE OBRA deve estar marcada.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● Caso a Opção Extra FINANCIAR MAO DE OBRA não estiver disponível ou não exista um valor de Mão de Obra custeável, não é
              possível custear a Mão de Obra.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● O valor de Mão de Obra é o valor que o(a) cooperado(a) informa que excede o valor dos Insumos. Exemplo:
              <br />
            </p>
            <table
              style={{
                color: '#000',
                borderCollapse: 'collapse',
                width: '50%',
                marginBottom: '20px',
              }}
            >
              <thead>
                <tr>
                  <th style={{ color: '#000', padding: '8px', textAlign: 'left' }}>MÃO DE OBRA</th>
                  <th style={{ color: '#000', padding: '8px', textAlign: 'left' }}>INSUMOS</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td style={{ color: '#000', padding: '8px', textAlign: 'left' }}>R$ 2.000,00</td>
                  <td style={{ color: '#000', padding: '8px', textAlign: 'left' }}>R$ 5.000,00</td>
                </tr>
              </tbody>
            </table>
            <p style={{ color: '#000', textAlign: 'left' }}>
              <strong>Simulação do Cálculo</strong>
            </p>
            <pre
              style={{
                backgroundColor: '#f5f5f5',
                padding: '10px',
                borderRadius: '4px',
                lineHeight: '1.5',
                marginBottom: '20px',
                color: '#000',
                textAlign: 'left',
              }}
            >
              {`Opções do(a) cooperado(a):
RECURSO PRÓPRIO:     R$ 2.500,00
CUSTEIO FRÍSIA:     +R$ 3.500,00
______________________________
TOTAL:               R$ 6.000,00`}
            </pre>
            <p style={{ color: '#000', textAlign: 'left' }}>
              Dos quais R$ 5.000,00 são referentes ao valor integral dos insumos e os R$ 1.000,00 excedentes são referentes ao valor parcial
              da Mão de Obra.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● Caso NÃO seja optado por FINANCIAR MAO DE OBRA, a somatória dos valores informados pelo(a) cooperado(a) não pode ultrapassar
              o valor de Insumos.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● Caso SEJA optado por FINANCIAR MAO DE OBRA, a somatória dos valores informados pelo(a) cooperado(a) não pode ultrapassar o
              de valor de Insumos + valor de Mão de Obra.
            </p>
            <p style={{ color: '#000', textAlign: 'left' }}>
              ● Existem algumas validações de regras que são provenientes do sistema e são validadas assim que clicar em Salvar Opção de
              Custeio.
            </p>
            <StyledButton onClick={() => setShowCalcModal(false)}>Fechar</StyledButton>
          </ModalContent>
        </ModalOverlay>
      )}

      <StyledButton
        style={{
          backgroundColor: btnViewColor,
          color: '#000',
          marginTop: '1rem',
        }}
        onClick={handleToggle}
      >
        {btnViewText}
      </StyledButton>
    </div>
  );
});

/* ========================= App Component ========================= */
const App = ({ t }: WithTranslation) => {
  const [dadosCusteio, setDadosCusteio] = useState<IDadosCusteio[]>([]);
  const [safras, setSafras] = useState<ISafra[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [selectedSafra, setSelectedSafra] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [cgpMapping, setCgpMapping] = useState<{ [key: number]: { vlTotal: number; vlDisponivel: number } }>({});

  const userRegistration = useSelector<IRootState, number>(
    state => state.authentication.selectedInfo?.CD_PESSOA ?? state.authentication.coreInfo?.cd_pessoa ?? 0
  );

  useEffect(() => {
    const fetchSafras = async () => {
      try {
        const response = await apiCore.get('/OpcaoCusteio/Safras');
        if (response.data.sucesso) {
          setSafras(response.data.safras);
          setError(null);
        } else {
          setError(response.data.mensagem);
        }
      } catch (error) {
        console.error('Erro ao buscar safras da API:', error);
        setError('Erro ao buscar safras');
      }
    };
    fetchSafras();
  }, []);

  const updateSingleCard = async (cdAgrupador: number) => {
    if (selectedSafra != null) {
      try {
        const response = await apiCore.get<IDadosCusteioData>(
          `/OpcaoCusteio/DadosOpcoes?cdSafra=${selectedSafra}&nrMatricula=${userRegistration}`
        );
        if (response.data.sucesso) {
          const updatedCard = response.data.opcoes.find(op => op.cdAgrupador === cdAgrupador);
          if (updatedCard) {
            setDadosCusteio(prev => prev.map(op => (op.cdAgrupador === cdAgrupador ? updatedCard : op)));
          }
        }
      } catch (error) {
        console.error('Erro ao atualizar o card:', error);
      }
    }
  };

  const fetchDadosOpcoes = async () => {
    if (selectedSafra != null) {
      setLoading(true);
      try {
        const response = await apiCore.get<IDadosCusteioData>(
          `/OpcaoCusteio/DadosOpcoes?cdSafra=${selectedSafra}&nrMatricula=${userRegistration}`
        );
        if (response.data.sucesso) {
          setDadosCusteio(response.data.opcoes);
          setError(null);
        } else {
          setError(response.data.mensagem);
        }
      } catch (error) {
        console.error('Erro ao buscar dados de custeio da API:', error);
        setError('Erro ao buscar dados de custeio');
      } finally {
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchDadosOpcoes();
  }, [selectedSafra, userRegistration]);

  const handleSafraChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedSafra(Number(e.target.value));
  };

  const updateCgpMapping = (cdTipoAtividade: number, data: { vlTotal: number; vlDisponivel: number }) => {
    setCgpMapping(prev => ({ ...prev, [cdTipoAtividade]: data }));
  };

  const sortedDadosCusteio = dadosCusteio.slice().sort((a, b) => a.cdTipoAtividade - b.cdTipoAtividade);

  return (
    <>
      {loading ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <div style={{ maxWidth: '100%', margin: '2rem auto', fontFamily: 'Arial, sans-serif' }}>
          <StyledPageTitleContainer margin={'10px 0 25px 0'}>
            <MainPageTitle fontSize={30} text={t('Opções de Custeio')} />
          </StyledPageTitleContainer>
          {error && <div style={{ color: 'red' }}>{error}</div>}
          <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <select
              id="safra-select"
              value={selectedSafra ?? ''}
              onChange={handleSafraChange}
              style={{
                minHeight: '53px',
                minWidth: '250px',
                textAlign: 'left',
                border: '2px solid #4e6ba7',
                borderRadius: '4px',
                cursor: 'pointer',
                backgroundColor: '#f1f1f1',
                marginBottom: '1rem',
              }}
            >
              <option value="">{t('Selecione uma safra')}</option>
              {safras.map(safra => (
                <option key={safra.cdSafra} value={safra.cdSafra}>
                  {safra.nmSafra}
                </option>
              ))}
            </select>
          </div>
          <div
            style={{
              marginTop: '1rem',
              background: '#e6eaf2',
              boxShadow: '0px 3px 6px #00000029',
              borderRadius: '16px',
              margin: '5px',
              padding: '20px',
              textAlign: 'center',
              fontSize: '16px',
              fontFamily: `'Montserrat', sans-serif`,
              color: '#000000',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-around',
            }}
          >
            {t('Em caso de dúvidas, favor entrar em contato com o Setor Financeiro pelo telefone (42) 3231-9034')}
          </div>
          {selectedSafra && sortedDadosCusteio.length === 0 && (
            <div
              style={{
                background: '#fff3cd',
                color: '#856404',
                border: '1px solid #ffeeba',
                borderRadius: '4px',
                padding: '1rem',
                marginBottom: '1rem',
                textAlign: 'center',
              }}
            >
              {t('Você ainda não possui programação de insumos para custear na safra selecionada')}
            </div>
          )}
          {sortedDadosCusteio.map(item => (
            <CostOptions
              key={item.cdAgrupador}
              data={item}
              reloadData={fetchDadosOpcoes}
              cgpMapping={cgpMapping}
              updateCgpMapping={updateCgpMapping}
              updateSingleCard={updateSingleCard}
            />
          ))}
          <ToastContainer />
        </div>
      )}
    </>
  );
};

export default withTranslation()(App);
